import dispatchEvent from './dispatch-event';

const AD_MIN_HEIGHT = 0;
const LEADERBOARD_MIN_HEIGHT_MOBILE = 40;
const LEADERBOARD_MIN_HEIGHT_DESKTOP = 85;
const hasObserverSupport = 'MutationObserver' in window && 'ResizeObserver' in window;
const dismissBottomStickyButton = document.querySelector('.ad-bottom-right-sticky__close');
const bottomStickyContainer = document.querySelector('.bottom-right-sticky-container');
let alreadyTreatingLeaderboard = false;

window.handleAdsFreeMode = () => {
  if (window.HP.params.isWebview || window.HP.params.isLiveblogSlidePage) {
    window.waitForGlobalCambria(
      () => window.getAppInfo,
      () => {
        window.getAppInfo().then((info) => {
          if (info && info.adsFree) {
            const webviewAds = document.querySelectorAll('.advertisement-holder, .taboola-container, .ad-leaderboard-flex, .ad-entry-body-bottom, .openweb-container, .below-entry-content .advertisement');
            webviewAds.forEach((element) => {
              const el = element;
              el.style.display = 'none';
            });
          }
        });
      },
      50, // poll every 50ms
      { fallback: 6000, caller: 'getAppInfo', fallbackCb: () => { console.log('AppFreeAds: fallback waitForGlobal after 6 secs'); } }, // suspend wait after 6 seconds
    );
  }
};

window.handleAdsFreeMode();

const treatLeaderboardFlexTempSticky = (ad) => {
  alreadyTreatingLeaderboard = true;
  let testHeight = ad.offsetHeight;
  const setIntervalId = setInterval(() => {
    const adIframe = ad.querySelector('iframe');
    if (adIframe && testHeight === adIframe.offsetHeight && testHeight >= LEADERBOARD_MIN_HEIGHT_MOBILE) {
      ad.classList.add('treated');
      dispatchEvent('leaderboard-treated'); // used in nav.js
      clearInterval(setIntervalId);
    } else {
      testHeight = adIframe?.offsetHeight || ad.offsetHeight;
    }
  }, 1000);
};

const treatAd = (ad) => {
  if (!ad.parentNode) return;
  const adParent = ad.parentNode.classList.contains('advertisement-sticky-wrapper')
    ? ad.parentNode.parentNode : ad.parentNode;
  const isLeaderboardFlex = ad.classList.contains('ad-leaderboard-flex');
  const isLeaderboardFlexTempSticky = isLeaderboardFlex && ad.classList.contains('temp-sticky');
  const isDesktop = window.HUFFPOST?.params?.device === 'desktop';
  /* eslint-disable no-nested-ternary */
  const adMinHeight = isLeaderboardFlex ? (isDesktop ? LEADERBOARD_MIN_HEIGHT_DESKTOP : LEADERBOARD_MIN_HEIGHT_MOBILE) : AD_MIN_HEIGHT;
  const adHeight = Math.max(ad.height || ad.offsetHeight);
  /* eslint-enable no-nested-ternary */
  const hasHeight = adHeight > adMinHeight;

  if (isLeaderboardFlexTempSticky) {
    if (alreadyTreatingLeaderboard) return;
    treatLeaderboardFlexTempSticky(ad);
  } else {
    ad.classList.toggle('treated', hasHeight);
  }

  if (!!adParent && adParent.classList.contains('advertisement-holder')) {
    adParent.classList.toggle('loaded', hasHeight);
  }
};

if (hasObserverSupport) {
  const resizeObserver = new ResizeObserver((entries) => {
    entries.forEach(({ target: ad }) => {
      treatAd(ad);
    });
  });

  document.querySelectorAll('.advertisement').forEach((ad) => {
    treatAd(ad);
    resizeObserver.observe(ad);
  });

  /**
   * Detect when ads are later injected onto the page. If a new ad is detected, add it to the
   * resizeObserver.
   * NOTE: Unknown why but these ads could not be detected by mutation observer. Instead, the script
   * will listen for this costume event.
   */
  document.addEventListener('ad-injected', (event) => {
    treatAd(event.detail.ad);
    resizeObserver.observe(event.detail.ad);
  });
} else {
  setInterval(() => {
    document.querySelectorAll('.advertisement').forEach((ad) => {
      treatAd(ad);
    });
  }, 50);
}

// MORE-IN-LIFE ZONE BACKGROUND LOGIC
const moreInLife = document.querySelector('.zone--more-in-life');
if (moreInLife) {
  document.addEventListener('scroll', () => {
    const rect = moreInLife.getBoundingClientRect();
    const afterHeight = parseInt(window.getComputedStyle(moreInLife, ':after').height, 10);

    moreInLife.classList.toggle('sticky', rect.top < 0 && rect.bottom > afterHeight);
    moreInLife.classList.toggle('sticky-bottom', rect.top < 0 && rect.bottom <= afterHeight);
  });
}

// TOOLBAR AD LOGIC
if (bottomStickyContainer && dismissBottomStickyButton) {
  dismissBottomStickyButton.addEventListener('click', () => {
    bottomStickyContainer.classList.add('slide-out');
  });
}
