import abeagle from './abeagle';
/*
* This script is used to handle the abOutbrainTest test
* The test is used to test the removal of outbrain from articles

* The test is a multi-variant test with the following variants:
* control - outbrain is present
* variant1 - outbrain is removed from the site and an AD is added in right rail where outbrain was, nothing replaces outbrain in other sections
* variant2 -  a) outbrain is removed from the site and an AD is added in right rail where outbrain was
              b) if related articles are present we add machine learning recommend to add a 4th items in the related articles module
              if its not present we add it with 4 articles from the recommend machine learning algorithm.
              c) Replace Bottom Recirc #1 to be filled by our Trending in [the article’s parent vertical] algorithm
              d) Bottom Recirc #1 is modified to include 5 cards instead of 3 and open web ad is moved into the reirc as if it was the 6th card
              e) Bottom Recirc #2 is modified to include 3 cards per row instead of 4, also ad logic is modified so that ad is inserted within each row instead of between each 2 rows of cards, so row one has card | card | ad, row two has ad | card | card, etc.
* variant3 - Includes everything that  as variant2 has except related articles changes and the content of the recircs is different:
*             a) Bottom Recirc #1 is filled with Trending in the 'huffpost-shopping' section
*             b) Bottom Recirc #2 is filled with most recent in the 'huffpost-shopping' section

* This file handles adding a class to the body element to indicate the test is running and which variant is being used
* Other files that handle test changes are: RelatedArticles.jsx, entryRecircController.js, Zone.jsx, Content.jsx, recirc.js and in a smaller part module/Entry.jsx and Zone/SeeMore.jsx
* NOTE: in order to have the ad in the recirc only load the size we need we had to create a new ad slot with adOps help, the name is "recirc" and its used in the recircs in variants 2 and 3
* NOTE: to better control outbrain viewability we are only adding the outbrain script on the control variant or other variants if it is the life department so we don't remove the inline video outbrain module
*       On non article pages the outbrain articles is added via the modulous OUTBRAIN BuzzFeed Network module, once this tests are done if we keep outbrain we need to remove the logic that excludes the script form bpages from that module
*/

const testSlug = 'hp_outbrain_remove_test';
const { edition, isEntry } = window.HP.params;
const { modulousTags } = window.HP.entry;
const isUS = edition === 'us';
const isLife = window.HP?.entry?.departmentSlug === 'lifestyle';
const isFeaturedNews = window.HP?.params?.isFeaturedNews;

function removeOutbrainModules() {
  const outbrainModules = document.querySelectorAll('.outbrain-module');

  // Loop through the elements and remove those that do not have an ancestor with `.cli-advertisement` (in order to not remove the LIFE inline video outbrain module)
  outbrainModules.forEach((module) => {
    if (!module.closest('.cli-advertisement')) {
      module.remove();
    }
  });
}

function addOutbrainScript() {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = '//widgets.outbrain.com/outbrain.js';
  document.head.appendChild(script);
}

if (isEntry && isUS && !isFeaturedNews) {
  const hasExcludeTag = modulousTags.includes('@nooutbrain') || modulousTags.includes('@notaboola') || modulousTags.includes('@sponsor');
  const relatedArticles = document.querySelector('.cli-related-articles');
  const recircZones = document.querySelectorAll('.below-entry-content .zone');

  if (hasExcludeTag) {
    window.HP.params.abOutbrainTest.doNotPublishEvent = true;
  }

  if (window.HP.params.abeagle && Array.isArray(window.HP.params.abeagle) && window.HP.params.abeagle.includes(testSlug)) {
    document.addEventListener('abeagleEventCambria', () => {
      const variant = abeagle.getExperimentVariant(testSlug);
      const bodyElement = document.querySelector('body.entry');
      if (variant && !hasExcludeTag) {
        bodyElement.classList.add(`ab-${testSlug}`);
        bodyElement.classList.add(`ab-${testSlug}--${variant}`);
        if (variant === 'control') {
          addOutbrainScript();
        } else if (isLife) {
          removeOutbrainModules();
          addOutbrainScript();
        }
      } else {
        bodyElement.classList.add(`ab-${testSlug}`);
        bodyElement.classList.add(`ab-${testSlug}--control`);
        addOutbrainScript();
      }
    }, { once: true });
    window.HP.params.abOutbrainTest.ready = true;
    // some code to check if the related articles and recirc zones are present if for some reason they aren't we set the test ready to continue code execution., else this is done in each component
    if (relatedArticles && relatedArticles.children.length === 0) {
      window.HP.params.abOutbrainTest.relatedModuleReady = true;
    }
    if (recircZones.length === 0) {
      window.HP.params.abOutbrainTest.bottomRecirc1Ready = true;
      window.HP.params.abOutbrainTest.bottomRecirc2Ready = true;
    }
  }
}
